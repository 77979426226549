<template>
  <div>
    <div class="chart-actions">
      <div
        @click="showChart = true"
        class="pointer activate-chart"
        :class="{ active: showChart }"
      >
        Chart
      </div>
      <div
        @click="showChart = false"
        class="pointer activate-table"
        :class="{ active: !showChart }"
      >
        Table
      </div>
    </div>
    <div class="table-data" v-if="showHighCharts && !showChart">
      <div class="title-section">
        <div class="title">{{ chartOptions.title.text }}</div>
        <div class="subtitle">{{ chartOptions.subtitle.text }}</div>
      </div>
      <el-table class="list-table" height="65vh" :data="getChartOptionsTable">
        <el-table-column
          fixed
          min-width="300"
          :label="__('Series')"
          prop="series"
        />
        <el-table-column
          v-for="(column, index) in chartOptionsTableColumns"
          :key="index"
          :label="column"
          :prop="column"
        >
        </el-table-column>
      </el-table>
    </div>
    <highcharts
      v-loading="isLoading"
      v-show="showHighCharts && showChart"
      class="chart"
      :options="chartOptions"
    ></highcharts>

    <div class="no-content" v-if="!showHighCharts">
      {{ getMessage }}
    </div>
  </div>
</template>

<script>
import {
  getChartOptionsForReportInstance,
  getChartTableOptionsForReportInstance
} from "@/api/reports";
import _ from "lodash";

export default {
  props: {
    reportInstance: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      chartOptions: {},
      chartOptionsTable: {},
      isLoading: false,
      showChart: true
    };
  },

  methods: {
    generateChartOptions() {
      this.isLoading = true;
      getChartOptionsForReportInstance(this.reportInstance.report_instance_id)
        .then(({ data }) => {
          this.chartOptions = data;
        })
        .catch(err => {
          this.$message({
            message: err.message || __("Sorry, Unable to load chart"),
            type: "error"
          });
          this.$emit("error", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    generateChartTableOptions() {
      this.isLoading = true;
      getChartTableOptionsForReportInstance(
        this.reportInstance.report_instance_id
      )
        .then(({ data }) => {
          this.chartOptionsTable = data;
        })
        .catch(err => {
          this.$message({
            message: err.message || __("Sorry, Unable to load table"),
            type: "error"
          });
          this.$emit("error", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    getMessage() {
      if (this.showChart) {
        return __("Sorry, Chart cannot be loaded");
      } else {
        return __("Sorry, Table cannot be loaded");
      }
    },
    emptyChartOptions() {
      return _.isEmpty(this.chartOptions);
    },
    emptyDataTable() {
      return _.isEmpty(this.chartOptionsTable);
    },
    showHighCharts() {
      return (
        this.isLoading ||
        (!this.isLoading && !this.emptyChartOptions && !this.emptyDataTable)
      );
    },
    getChartOptionsTable() {
      return this.chartOptionsTable;
    },
    chartOptionsTableColumns() {
      if (!_.isEmpty(this.chartOptionsTable)) {
        let record = this.chartOptionsTable[0];
        return Object.keys(record).filter(key => key !== "series");
      }
      return [];
    }
  },

  watch: {
    "reportInstance.report_request_id": {
      immediate: true,
      handler() {
        this.generateChartOptions();
        this.generateChartTableOptions();
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/table.scss";
.chart-actions {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }
}

.active {
  color: mediumblue;
  text-decoration: underline;
}

.table-data {
  .title-section {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
      sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      color: #333;
    }

    .subtitle {
      font-size: 12px;
      color: #666;
    }
  }
}
</style>
